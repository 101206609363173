<template>
    <div v-if="!isLoadingBil">
        <section class="aa-contenedor-basico fade-in">
            <div class="aa-contenedor-basico-portada">
                <div class="portada"></div>
            </div>

            <div class="aa-contenedor-basico-titulo">
                <span class="aa-text-h1 text-start d-block text-uppercase p-1">
                    Recursos Bilingüe
                </span>
                <span class="line"></span>
            </div> 

            
                <div class="clases-recursos" v-for="(recurso, index) in recurosGeneral" :key="index">
                    <div class="rec-clas aa-text-h2-5 fw-600 px-2 rec-clas text-start">
                        {{index}}
                    </div>
                    <div class="asignaturas" v-for="({asignatura, ficheros}, idx) in recurso" :key="idx">
                        <div class="asignatura">{{asignatura}}</div>
                        <DescargaDocumento :documentos="ficheros" :titulo="'Documentos'" class="mb-3" />
                    </div>
                </div>
            
            <span v-if="isLoadingBil"> Cargando </span>
        </section>
    </div>
    <div v-else>
        <PrensaSk />
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import PrensaSk from '../components/skeleton/PrensaSk.vue'
import useBilin from '../composables/useBilin'



export default {
    components: {
    PrensaSk,
    DescargaDocumento: defineAsyncComponent( () => import('../components/Area/DescargaDocumento.vue')),
},
    setup() {
        // const {cargaMas, cargaInicial, loaded, actividadesList, isLoading, scrollComponent } = useRecu()
    const { getRecursosGenerales, isLoadingBil, recurosGeneral } = useBilin()

    getRecursosGenerales()
    // cargaInicial()
    
    return {
        isLoadingBil, 
        recurosGeneral
    }
    }
}
</script>

<style lang="sass" scoped>
.aa-bilingue
    $root:&
    
    display: flex
    flex-direction: column
    flex-wrap: wrap
    margin-top: 96px
    &-portada
        display: flex
        flex-direction: column
        .portada-bilingue
            order:1
            width: 100%
            margin-bottom: 0px

    .contenedor
        .img-contenedor
            width: 100%
            height: 118px
            img
                width: 100%
                object-fit: cover
                height: 100%
        h4
            width: 100%
            margin-block-start: 0.3rem

.portada
    background-image: url('../../../assets/img/fotos/bilingue_resources.jpg')
</style>