import { marked } from 'marked'

const recortaTextoMarcado = (texto, longitud) => {
    if (texto){
        return marked(texto.slice(0,longitud) + '...')
    }else{
        return false
    }
}

const getPortadaMultiple = (media) => {

    if (media.data){

        if (media.data[0].attributes.formats.small){
            return media.data[0].attributes.formats.small.url
        }else{
            return media.data[0].attributes.url
        }

    }else{
        return 'https://fraybartolome.s3.eu-west-1.amazonaws.com/ESCUDO_FRAY_27cb047579.png'
    }

}


export {
    recortaTextoMarcado,
    getPortadaMultiple
}