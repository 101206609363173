import { ref } from "vue"
// import { marked } from 'marked'

import publicApi from "@/api/publicApi"
import { marked } from "marked"
import * as lodash from 'lodash'

import defaultImg from '@/assets/img/ESCUDO_FRAY.png'

const useBilin = () => {
    const isLoadingBil = ref(true)
    const bilingue = ref()
    const loaded = ref()
    const recurosGeneral = ref()


    const getBasico  = async () => {
        try {
            const { data } =  await publicApi.get('bilinguismo?populate[portada][fields]=url&populate[documentos][fields]=url,caption')
            isLoadingBil.value = false
            bilingue.value = {
                nombre: 'Bilingüismo',
                portada: data.data.attributes.portada.data.attributes.url,
                introduccion: marked(data.data.attributes.introduccion),
                informacion: marked(data.data.attributes.informacion),
                escuela: marked(data.data.attributes.escuela),
                documentos: (data.data.attributes.documentos) ? data.data.attributes.documentos.data : null,
            }
        } catch (error) {
            bilingue.value = null
            isLoadingBil.value = false
        }
    }

    const getClases  = async () => {
        try {
            const { data } =  await publicApi.get('bilinguismo?populate[clases][populate][0]=cursos&populate[clases][populate][1]=profesor')
            isLoadingBil.value = false
            bilingue.value = {
                clases: data.data.attributes.clases.map( c => {
                    return {
                        asignatura: c.asignatura,
                        cursos: c.cursos.data.map( cc => cc.attributes.curso),
                        profesor: c.profesor.data.attributes.nombre
                    }
                })
            }
        } catch (error) {
            bilingue.value = null
            isLoadingBil.value = false
        }
    }

    const getAuxiliares  = async () => {
        try {
            const { data } =  await publicApi.get('bilinguismo?populate[0]=auxiliar&populate[1]=auxiliar.foto&populate[2]=auxiliar.presentacion&populate[3]=auxiliar.recursos&populate[4]=auxiliar.actividades')
            bilingue.value.auxiliares = data.data.attributes.auxiliar.map( a => {
                    return {
                        id: a.id,
                        curso: a.curso,
                        nombre: a.nombre,
                        presentacion: (a.presentacion.data) ? a.presentacion.data.attributes.url : false,
                        foto: (a.foto.data) ? a.foto.data.attributes.url : defaultImg,
                        actividades: a.actividades.length,
                        recursos: a.recursos.length
                    }
                })
            
            isLoadingBil.value = false
        } catch (error) {
            bilingue.value = null
            isLoadingBil.value = false
        }
    }
    
    const getRecursos  = async (id = 1) => {
        try {
            const { data } =  await publicApi.get('bilinguismo?populate[0]=auxiliar.recursos.ficheros')
            
            const auxiliares = data.data.attributes.auxiliar.filter( a => a.id === parseInt(id))
            
            const recursos = auxiliares[0].recursos.map( r => {
                return {
                    clase: r.clase.trim(),
                    asignatura: r.asignatura,
                    info: r.info,
                    ficheros: r.ficheros.data
                }
            })

            bilingue.value = {
                nombre: auxiliares[0].nombre,
                recursos: lodash.groupBy(recursos,'clase')
            }
            

            isLoadingBil.value = false
        } catch (error) {
            bilingue.value = null
            isLoadingBil.value = false
        }
    }

    const getRecursosGenerales = async () => {
        try {
            isLoadingBil.value = true
            const { data } =  await publicApi.get('bilinguismo?populate[0]=recursos.ficheros')
            const recursos = data.data.attributes.recursos.map( r => {
                return {
                    clase: r.clase.trim(),
                    asignatura: r.asignatura,
                    info: r.info,
                    ficheros: r.ficheros.data
                }
            })

            recurosGeneral.value = lodash.groupBy(recursos,'clase')
            console.log(recurosGeneral.value)

            isLoadingBil.value = false
        } catch (error) {
            bilingue.value = null
            isLoadingBil.value = false
        }
    }

    const getActividadesAuxiliar  = async (id = 1) => {
        try {
            const { data } =  await publicApi.get('bilinguismo?populate[0]=auxiliar.actividades.imagen')
            
            const auxiliares = data.data.attributes.auxiliar.filter( a => a.id === parseInt(id))
            
            
            const actividades = auxiliares[0].actividades.map( a => {
                return {
                    id: a.id,
                    descripcion: marked(a.descripcion),
                    imagen: a.imagen.data.attributes.url
                }
            })

            bilingue.value = {
                nombre: auxiliares[0].nombre,
                actividades
            }

            isLoadingBil.value = false
        } catch (error) {
            bilingue.value = null
            isLoadingBil.value = false
        }
    }





    return {
        bilingue,
        isLoadingBil,
        loaded,
        recurosGeneral,
        getBasico,
        getClases,
        getAuxiliares,
        getRecursos,
        getActividadesAuxiliar,
        getRecursosGenerales,
    }

}


export default useBilin