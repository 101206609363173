<template>
    <div v-if="!isLoading">
        <section class="aa-contenedor-basico fade-in">
            <div class="aa-contenedor-basico-portada">
                <div class="portada"></div>
            </div>

            <div class="aa-contenedor-basico-titulo">
                <span class="aa-text-h1 text-start d-block text-uppercase p-1">
                    Actividades Bilingüe
                </span>
                <span class="line"></span>
            </div> 

            <div class='scrolling-component mb-1' ref='scrollComponent'>
                <NoticiaGrid v-for='actividad in actividadesList' :datos="actividad" :key="actividad.id" :url="'detalleactividad'" class="fade-in" />
            </div>
            
            <span v-if="isLoading"> Cargando </span>
        </section>
    </div>
    <div v-else>
        <PrensaSk />
    </div>
</template>

<script>
import { defineAsyncComponent, onMounted, onUnmounted } from '@vue/runtime-core'
import useActividadesb from '../composables/useActividadesb'
import PrensaSk from '../components/skeleton/PrensaSk.vue'



export default {
    components: {
    NoticiaGrid: defineAsyncComponent(() => import("../components/Prensa/NoticiaGrid.vue")),
    PrensaSk
},
    setup() {
        const {cargaMas, cargaInicial, loaded, actividadesList, isLoading, isLoadingMas, scrollComponent } = useActividadesb()
    
    cargaInicial()
    
    onMounted(() => {
        window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
        window.removeEventListener("scroll", handleScroll)
    })
    

    const handleScroll = () => {
        let element = scrollComponent.value;
        if (element.getBoundingClientRect().bottom < window.innerHeight && !isLoadingMas.value) {
            cargaMas()
        }
    }
    return {
        onLoaded: () => loaded.value = true,
        actividadesList,
        cargaMas,
        isLoading,
        loaded, 
        scrollComponent,
    }
    }
}
</script>

<style lang="sass" scoped>
.aa-bilingue
    $root:&
    
    display: flex
    flex-direction: column
    flex-wrap: wrap
    margin-top: 96px
    &-portada
        display: flex
        flex-direction: column
        .portada-bilingue
            order:1
            width: 100%
            margin-bottom: 0px

    .contenedor
        .img-contenedor
            width: 100%
            height: 118px
            img
                width: 100%
                object-fit: cover
                height: 100%
        h4
            width: 100%
            margin-block-start: 0.3rem

.portada
    background-image: url('../../../assets/img/fotos/actividades.jpg')
</style>