import { ref } from "vue"
// import { marked } from 'marked'

import publicApi from "@/api/publicApi"
import { marked } from "marked"
import { getPortadaMultiple } from "../helpers/utils"

import defaultImg from '@/assets/img/ESCUDO_FRAY.png'

const useActividadesb = () => {
    const isLoading = ref(true)
    const isLoadingMas = ref(false)
    const loaded = ref()
    const actividadDetalle = ref()

    const actividadesList = ref([])
    const inicioActividades = ref(0)
    const finActividades = ref(5)
    const totalActividades = ref(0)

    const scrollComponent = ref(null)


    const leeActividad = async(id) => {
        if (!id) return
        
        actividadDetalle.value = null

        try{
            const { data } =  await publicApi.get(`actividades-bilingues/${id}?populate[media][fields][0]=url&populate[documentos][fields][0]=url,caption`)
            actividadDetalle.value = {
                id: data.data.id,
                titulo: data.data.attributes.Titulo,
                cuerpo: marked(data.data.attributes.decripcion),
                portada: (data.data.attributes.media.data) ? data.data.attributes.media.data[0].attributes.url : defaultImg,
                imagenes: (data.data.attributes.media.data) ? data.data.attributes.media.data : null,
                documentos: (data.data.attributes.documentos) ? data.data.attributes.documentos.data : null
            }
            isLoading.value = false

        }catch(error){
            isLoading.value = false
            actividadDetalle.value = null
        }
    }

    const cargaInicial = async () => {
        try{
            isLoading.value = true
            
            const { data } = await publicApi.get('actividades-bilingues?populate[media][fields][0]=*&pagination[start]=0&pagination[limit]=405&sort[0]=Curso:desc&sort[1]=Titulo:asc')
            
            const tratadas = data.data.map( a => {
                return {
                    id: a.id,
                    curso: a.attributes.Curso,
                    titulo: a.attributes.Titulo,
                    portada: getPortadaMultiple(a.attributes.media)
                }
            })
            actividadesList.value.push(...tratadas)
            isLoading.value = false
            totalActividades.value = data.meta.pagination.total

        }catch (error){
            console.log(error);
        }
    }

    const cargaActividades = async() => {
        try{
            isLoadingMas.value = true
            
            const { data } = await publicApi.get(`actividades-bilingues?populate[media][fields][0]=url,formats&pagination[start]=${inicioActividades.value}&pagination[limit]=${finActividades.value}&sort[0]=Curso:desc&sort[1]=Titulo:asc`)
            const tratadas = data.data.map( a => {
                return {
                    id: a.id,
                    curso: a.attributes.Curso,
                    titulo: a.attributes.Titulo,
                    portada: getPortadaMultiple(a.attributes.media)
                }
            })
            
            actividadesList.value.push(...tratadas)
            isLoadingMas.value = false

        }catch (error){
            console.log(error);
        }
    }

    const cargaMas = async () => {
        if (inicioActividades.value >= totalActividades.value) return
        inicioActividades.value += 5
        cargaActividades()
    }


    return {
        leeActividad,
        cargaInicial,
        cargaMas,
        actividadDetalle,
        scrollComponent,
        finActividades,
        inicioActividades,
        actividadesList,
        loaded,
        isLoading,
        isLoadingMas,
    }

}


export default useActividadesb